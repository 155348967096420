<template>
	<div
		:class="['user-avatar', { 'user-avatar--border': border }]"
		:style="{ backgroundColor: avatar.getColorFromLocal ?? color, width: `${size}px`, minWidth: `${size}px`, height: `${size}px` }">
		<span class="user-avatar__initials">{{ initials }}</span>
	</div>
</template>

<script setup lang="ts">
	import { getInitials } from "@/utils/initials";
	import { Avatar } from "@/assets/ts/global/models/Avatar";

	const props = withDefaults(
		defineProps<{
			fullName: string;
			border?: boolean;
			color?: string;
			size?: number;
			userId: string;
		}>(),
		{
			border: false,
			color: "var(--red-1)",
			size: 28,
		},
	);

	const initials: ComputedRef<string> = computed((): string => getInitials(props.fullName));

	const avatar = ref(new Avatar(props.userId));

	watch(
		() => props.userId,
		(id) => {
			avatar.value = new Avatar(id);
		},
	);
</script>

<style lang="scss" scoped>
	@import "user-avatar";
</style>

export class Avatar {
	private colors: string[] = ["#A18B36", "#bc34cb", "#A358DF", "#F65F7C", "#258750", "#E1C34C", "#ce2b2b", "#502fe5", "#290bc5", "#3f3448", "#f58033", "#80d21d"];

	constructor(private chatId: string) {
		if (!this.getColorFromLocal) {
			this.setColorToLocal();
		}
	}

	get localKey(): string {
		return `color-user-${this.chatId}`;
	}

	get getRandomColor(): string {
		return this.colors[Math.floor(Math.random() * this.colors.length)];
	}

	setColorToLocal(): void {
		localStorage.setItem(this.localKey, this.getRandomColor);
	}

	get getColorFromLocal(): string | null {
		return localStorage.getItem(this.localKey);
	}
}
